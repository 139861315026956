/* eslint-disable import/prefer-default-export */

/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const autoLpSuccess = {
  title: "success stories",
  subTitle: "Our merchant",
};

export const AutoLPHero = {
  title: " High-performance tech to run & grow your automotive business",
  subtext:
    "Attract more customers, get paid, and keep customers coming back with a toolset that’s built for your business.",
  heroImg: "auto-lp2.jpg",
  imageClassName: "smaller-hero",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-auto",
  },
  fatTitle: "Automotive",
};

export const AutoLPWhiteGlove = {
  mainTitle: "Personalized support that’s working when you are",
  title: "Get the platinum service you deserve",
  content:
    "Whether you need to promote your business online, increase customer loyalty, improve your online reviews, or give your customers easier ways to pay, we have the right tools for the job. They all work side-by-side, making your life simpler, and they’re backed by local service and 24/7 support from real people who are ready to help. Because that’s how business should be.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-auto",
  },
};

export const AutoLPLargeFeatures = {
  sectionTitle: "Powerful tools. Customized to your needs",
  featureBlocks: [
    {
      blockTitle: "Cut the wires. Connect with customers. Get paid",
      blockImg: "auto-lp-1.png",
      blockSubTitle:
        "Run payments in the lobby, in the garage, or wherever your customers are. Then keep them coming back with built-in email capture and Marketing & Loyalty capabilities.",
      blockList: [
        "Wi-Fi and 4G connectivity",
        "Apple Pay, Google Pay, and NFC enabled",
        "Built-in receipt printer and scanner",
        "Cash discount and surcharge ready",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-auto",
      },
    },
    {
      blockTitle: "Increase sales with online appointment booking",
      blockSubTitle:
        "Bring sanity to your schedule and make it easy for customers to book appointments right from your website and Facebook page.",
      blockImg: "auto-lp-2.png",
      blockList: [
        "Online appointment booking",
        "Interactive calendar for drop-offs & appointments",
        "Employee shift management",
        "Mailing list builder",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-auto",
      },
    },
    {
      blockTitle: "Manage reviews. Boost your reputation",
      blockSubTitle:
        "Monitor, respond to, and improve your online reviews on Yelp, TripAdvisor, Facebook, and Google—all in one place.",
      blockImg: "auto-lp-3.png",
      blockList: [
        "Get notified when new reviews are posted",
        "Click over directly to review sites to respond",
        "Monitor your reputation as it improves over time",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-auto",
      },
    },
  ],
};

export const AutoLPTestimonials = {
  title: "What other automotive business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes. I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const AutoLPCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-auto",
  },
};
export const autoCsSlides = [
  {
    image: "ne-auto_imports.png",
    companyName: "Northeast Auto Imports",
    companyIs: "Auto garage",
    companyLogo: "NE-auto-imports.png",
    address: "120 Ferry St, Hudson, NH 03051",
    owner: "Derrek Griffith",
    website: "northeastautoimports.com",
    description:
      "SpotOn’s platform lets me communicate easily with my customers through email or text to schedule test drives and service appointments, leading to stronger relationships and more satisfaction—all while I’m on the road. Being able to manage reviews and process payments virtually is awesome, too. Plus, the cash discount saved me tons.",
    url: "/page-2",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "7+ weeks",
        numberType: "Appointments booked in advance",
      },
      {
        numbers: "4.9 stars",
        numberType: "Average review rating",
      },
    ],
  },
];

export const AutoLPTemplate = [
  {
    content: " Intuitive front-of-house",
    desc: "Streamline operations, accelerate growth, and provide guests with a five-star experience, in-house and online.",
    img: "book-open.png",
  },
  {
    content: "Cloud-based back-of-house",
    desc: "Manage employee schedules, menus, table layouts, pricing events, and more—from anywhere.",
    img: "urgent.png",
  },
  {
    content: "Online ordering",
    desc: "Offer commission-free online ordering directly to your customers for take-out, delivery, and dine-in.",
    img: "award.png",
  },
  {
    content: "Handheld POS",
    desc: "Enhance your guest experience and turn more tables with easy-to-use handhelds, part of our fully scalable hardware options.",
    img: "calc.png",
  },
  {
    content: "Elite guest data & reporting",
    desc: "Make smarter decisions with 90+ real-time reports, automated manager alerts and subscriptions, and enterprise reporting.",
    img: "pie-chart.png",
  },
  {
    content: "Seamless integration",
    desc: "Combine SpotOn with the software you already know and use, or integrate SpotOn’s intuitive software solutions for one easy-to-use system.",
    img: "git-merge.png",
  },
];

export const AutoLpUspData = [
  {
    content: "Wireless smart terminal",
    desc: "Get paid wherever your customers are, with customer email capture and integrated Marketing & Loyalty.",
    img: "terminal.png",
  },
  {
    content: "Review management",
    desc: "Easily monitor, respond to, and improve your online reviews from Yelp, Google, Facebook, and Tripadvisor.",
    img: "reviews.png",
  },
  {
    content: "Appointments",
    desc: "Bring sanity to your schedule and make it easy for customers to book appointments right from your website and Facebook page.",
    img: "appointment.png",
  },
  {
    content: "Website",
    desc: "Get found online by more customers with a stunning personalized website built just for you at a do-it-yourself price.",
    img: "terminal.png",
  },
  {
    content: "Loyalty",
    desc: "Drive repeat visits with custom rewards and get the customer data you need to compete and win.",
    img: "loyalty.png",
  },
  {
    content: "Virtual terminal & invoicing",
    desc: "No hardware needed. Take  deposits, send invoices, and set up recurring billing plans with ease right from your computer.",
    img: "virtual_terminal.png",
  },
];
