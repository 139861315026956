import React from "react";
import loadable from "@loadable/component";
import LpLayout from "../../components/Layout/lp-layout";
import SEO from "../../components/seo";
// import LpLeadForm from "../../components/ConversionPath/LpLeadForm";
// import Image from "../../components/image";
// import SuccessStories from "../../components/SuccessStories/SuccessStories";
// import UspFeatures from "../../components/ConversionPath/UspFeatures";
import {
  AutoLpUspData,
  autoCsSlides,
  autoLpSuccess,
} from "../../data/landing/auto";

import ogImage from "../../images/global_assets/og-image.png";

const LpLeadForm = loadable(() =>
  import("../../components/ConversionPath/LpLeadForm")
);
const Image = loadable(() => import("../../components/image"));
const SuccessStories = loadable(() =>
  import("../../components/SuccessStories/SuccessStories")
);
const UspFeatures = loadable(() =>
  import("../../components/ConversionPath/UspFeatures")
);

export default function AutoLp() {
  return (
    <LpLayout phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="SpotOn Demo | Point-of-Sale"
        description="High-performance tech to run &amp; grow your automotive business."
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
        <div className="demo__content-wrapper">
          <div className="header-img">
            <Image
              imageName="auto-demo-img.png"
              className="lp-img"
              style={{ marginTop: 50 }}
            />
          </div>
          <div className="form-wrapper">
            <LpLeadForm
              header="High-performance tech to run &amp; grow your automotive business"
              subHeader="Attract more customers, get paid, and keep customers coming back with a toolset that’s built for your business."
              leadType="lead-gen"
              formId="ff4e9a6b-5db7-4946-bec4-bfb7c4a8b7fa"
              thankYouPath="/lp/thank-you"
            />
          </div>
        </div>
        <section style={{ marginTop: 60 }}>
          <UspFeatures data={AutoLpUspData} />
        </section>
        {/* <Badges /> */}
        <section style={{ marginTop: "60px", marginBottom: 60 }}>
          <SuccessStories sectionData={autoLpSuccess} slides={autoCsSlides} />
          {/* <TestmonialReviews sectionData={AutoLPTestimonials} /> */}
        </section>
        {/* <section style={{ marginBottom: 60 }}>
          <PartnerLogos styles={{ marginBottom: 40 }} />
        </section> */}
      </section>
    </LpLayout>
  );
}
